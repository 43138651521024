export const ActionTypes = {
  REGISTER: 'REGISTER',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  LOGIN: 'LOGIN',
  IS_AUTH: 'IS_AUTH',
  LOGOUT: 'LOGOUT',
  FETCH_ACCOUNT: 'FETCH_ACCOUNT',
  FETCH_SERVICE_REQUESTS: 'FETCH_SERVICE_REQUESTS',
  FETCH_OFFERS: 'FETCH_OFFERS',
  FETCH_TIMEBLOCKS: 'FETCH_TIMEBLOCKS',
  CREATE_OFFER: 'CREATE_OFFER',
  FETCH_LOGGED_IN_USER: 'FETCH_LOGGED_IN_USER',
  UPDATE_OFFER: 'UPDATE_OFFER',
  FETCH_SETTINGS: 'FETCH_SETTINGS',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_VEHICLE: 'UPDATE_VEHICLE',
  EXPIRE_SR: 'EXPIRE_SR',
  FETCH_MISSED_SERVICE_REQUESTS: 'FETCH_MISSED_SERVICE_REQUESTS',
  UPDATE_STRIPE_ID: 'UPDATE_STRIPE_ID',
  FETCH_SERVICES: 'FETCH_SERVICES',
  CREATE_SERVICE: 'CREATE_SERVICE',
  UPDATE_SERVICE: 'UPDATE_SERVICE',
  DELETE_SERVICE: 'DELETE_SERVICE',
  CREATE_PERK: 'CREATE_PERK',
  UPDATE_PERK: 'UPDATE_PERK',
  DELETE_PERK: 'DELETE_PERK',
  GET_LOGO: 'GET_LOGO',
  UPDATE_LOGO: 'UPDATE_LOGO',
  DELETE_LOGO: 'DELETE_LOGO',
  LIST_IMAGES: 'LIST_IMAGES',
  UPLOAD_IMAGES: 'UPLOAD_IMAGES',
  UPDATE_IMAGES: 'UPDATE_IMAGES',
  DELETE_IMAGES: 'DELETE_IMAGES',
  FETCH_VEHICLE_CLASSES: 'FETCH_VEHICLE_CLASSES',
  FETCH_VEHICLE_ENGINES: 'FETCH_VEHICLE_ENGINES',
  GENERATE_ACTIVITY_SUMMARY: 'GENERATE_ACTIVITY_SUMMARY',
  GENERATE_BALANCE_SUMMARY: 'GENERATE_BALANCE_SUMMARY',
  UPLOAD_PDF: 'UPLOAD_PDF',
  CREATE_RECCOMENDED_SERVICES: 'CREATE_RECCOMENDED_SERVICES',
  UPDATE_OFFER_REVISED_TAX: 'UPDATE_OFFER_REVISED_TAX',
  UPDATE_WORKING_HOURS: 'UPDATE_WORKING_HOURS',
  SERVICE_LIST: 'SERVICE_LIST',
}
