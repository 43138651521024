import * as React from 'react'
import { Box, Toolbar, CssBaseline, Grid, Alert } from '@mui/material'
import ServiceRequest from './tables/service-request'
import CurrentOffers from './tables/current-offers'
import Accepted from './tables/accepted'
import Completed from './tables/completed'
import MissedRequest from './tables/missed-requests'
import { useDispatch } from 'react-redux'
import { fetchLoggedInUser } from '../../redux/actions/user'
import { unwrapResult } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import { Account } from '../../models/account'
import { useState, useEffect } from 'react'
import { AppHeader } from '../../components/header/app_header'
import ErrorHandler from './error-handler'

const drawerWidth = 240

interface Props {
  window?: () => Window
}

export default function Layout(props: Props) {
  const [user, setUser] = React.useState<Account>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [alert, setAlert] = useState(false)
  const [error, setError] = useState(null)
  const clearError = () => setError(null)
  let formatCurrency = new Intl.NumberFormat('en-HOSSDDG', {
    style: 'currency',
    currency: 'USD',
  })

  useEffect(() => {
    const cognitoId = localStorage.getItem('cognitoId')
    if (cognitoId === undefined || cognitoId === null) {
      navigate('/')
    } else {
      dispatch<any>(fetchLoggedInUser(cognitoId))
        .then(unwrapResult)
        .then((user: any) => {
          if (user.cognito_id) {
            setUser(user)
          } else {
            localStorage.removeItem('userToken')
            navigate('/')
          }
        })
        .catch((rejectedValueOrSerializedError: any) => {
          setError(rejectedValueOrSerializedError)
        })
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', background: '#FCFCFC', marginTop: 8 }}>
      <CssBaseline />
      <AppHeader />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          px: 5,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {user && (
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {alert && (
              <Alert severity="success" sx={{ marginBottom: 5, fontFamily: 'Figtree' }}>
                Service Status Updated Successfully!
              </Alert>
            )}
            <Grid item md={12} xs={12} sm={12}>
              <ServiceRequest />
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <CurrentOffers formatCurrency={formatCurrency} />
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <Accepted setAlert={setAlert} />
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <Completed formatCurrency={formatCurrency} />
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <MissedRequest formatCurrency={formatCurrency} />
            </Grid>
          </Grid>
        )}
      </Box>
      <CssBaseline />
      <AppHeader />
      {error && <ErrorHandler error={error} clearError={clearError} />}
    </Box>
  )
}
