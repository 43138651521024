import { sanitizeNumber } from '../../../../components/helper/helper'
import { Service } from './inspection_upload/inspection_upload'
import { OfferBlockService } from './offer_create/OfferServiceBlock'
import { RecommendedService } from './service.model'

const validateAxlePads = (additionalData: any, frontAxle: boolean, rearAxle: boolean): boolean => {
  if (frontAxle && (!additionalData.LF_PAD || !additionalData.RF_PAD)) {
    return false
  }
  if (rearAxle && (!additionalData.LR_PAD || !additionalData.RR_PAD)) {
    return false
  }
  return true
}

const tirePositionsToMeasurementMapping = {
  LR: 'LR_MEASUREMENT',
  RR: 'RR_MEASUREMENT',
  LF: 'LF_MEASUREMENT',
  RF: 'RF_MEASUREMENT',
  LR_INSIDE: 'LR_INSIDE_MEASUREMENT',
  RR_INSIDE: 'RR_INSIDE_MEASUREMENT',
}

const validateTireData = (
  additionalData: {
    TIRE_POSITIONS?: any[]
    TIRE_BRAND?: string
    TIRE_MODEL?: string
    WARRANTY?: number
  },
  tirePositions: boolean,
  tireInfo: boolean
): boolean => {
  if (!additionalData) {
    return false
  }

  if (
    tirePositions &&
    (!Array.isArray(additionalData.TIRE_POSITIONS) || additionalData.TIRE_POSITIONS.length === 0)
  ) {
    return false
  }

  if (tirePositions) {
    if (!additionalData.TIRE_POSITIONS) {
      return false
    }
    for (const tire of additionalData.TIRE_POSITIONS) {
      if (!additionalData[tirePositionsToMeasurementMapping[tire]]) {
        return false
      }
    }
  }

  if (tireInfo) {
    if (
      !additionalData.TIRE_BRAND ||
      typeof additionalData.TIRE_BRAND !== 'string' ||
      additionalData.TIRE_BRAND.length === 0 ||
      !additionalData.TIRE_MODEL
    ) {
      return false
    }
  }

  return true
}

const validateCrankingAmps = (additionalData: any, crankingAmps: boolean): boolean => {
  if (
    crankingAmps &&
    (!additionalData.COLD_CRANKING_AMPS ||
      additionalData.COLD_CRANKING_AMPS <= 0 ||
      !additionalData.FACTORY_COLD_CRANKING_AMPS ||
      additionalData.FACTORY_COLD_CRANKING_AMPS <= 0)
  ) {
    return false
  }
  return true
}

const validateParts = (parts: any[]): boolean => {
  return !parts.some(
    (part) =>
      !part.name || part.quantity <= 0 || sanitizeNumber(part.price_per_unit) <= 0 || !part.number
  )
}

export const validateRecommendedServiceFields = (
  services: Service[],
  service: RecommendedService
): { isValid: boolean; reason?: string } => {
  const additionalData = (service.additional_data as Partial<any>) || {}

  const selectedService =
    service.types.find((s) => s.id === service.type) || services.find((s) => s.id === service.id)

  if (!selectedService) {
    return { isValid: false, reason: 'Please select a service' }
  }

  if (service.types.length > 0 && service.type === null) {
    return { isValid: false, reason: 'Please select a service type' }
  }

  const recommendedRequiredData = selectedService?.recommend_required_data || []
  const isTirePositions = recommendedRequiredData.includes('TIRE_POSITIONS')
  const isTireInfo = recommendedRequiredData.includes('TIRE_BRAND')
  const isCrankingAmps = recommendedRequiredData.includes('COLD_CRANKING_AMPS')
  const isFrontAxle =
    recommendedRequiredData.includes('LF_PAD') && recommendedRequiredData.includes('RF_PAD')
  const isRearAxle =
    recommendedRequiredData.includes('LR_PAD') && recommendedRequiredData.includes('RR_PAD')

  if (
    !validateTireData(additionalData, isTirePositions, isTireInfo) ||
    !validateCrankingAmps(additionalData, isCrankingAmps) ||
    !validateAxlePads(additionalData, isFrontAxle, isRearAxle) ||
    !validateParts(service.parts)
  ) {
    return { isValid: false, reason: 'Please fill in all required fields' }
  }

  if (
    !service.imageFiles ||
    (service.imageFiles.length === 0 &&
      (!service.uploadedImageFiles || service.uploadedImageFiles.length === 0))
  ) {
    return { isValid: false, reason: 'Please upload at least one image' }
  }

  return { isValid: true }
}

export const validateOfferServiceFields = (
  service: OfferBlockService,
  parentService: any
): boolean => {
  const serviceChild = parentService?.child
  const offer_required_data =
    serviceChild?.offer_required_data || parentService?.offer_required_data || []

  const additionalData = (service.additional_data as Partial<any>) || {}

  const isTirePositions = offer_required_data.includes('TIRE_POSITIONS')
  const isTireInfo = offer_required_data.includes('TIRE_BRAND')
  const isCrankingAmps = offer_required_data.includes('COLD_CRANKING_AMPS')
  const isFrontAxle =
    offer_required_data.includes('LF_PAD') && offer_required_data.includes('RF_PAD')
  const isRearAxle =
    offer_required_data.includes('LR_PAD') && offer_required_data.includes('RR_PAD')
  if (
    !validateTireData(additionalData, isTirePositions, isTireInfo) ||
    !validateCrankingAmps(additionalData, isCrankingAmps) ||
    !validateAxlePads(additionalData, isFrontAxle, isRearAxle) ||
    !validateParts(service.parts)
  ) {
    return false
  }

  return true
}
