import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import { Toolbar, Button, Typography, IconButton, Avatar, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { logoutUser, userIsAuth } from '../../redux/actions/user'
import { APP_FONT } from '../../constants/app_font'
import { ReactComponent as CarmaLogo } from '../../assets/images/carma_logo_white.svg'
import { ReactComponent as SupportIcon } from '../../assets/images/support_icon.svg'
import { ReactComponent as SettingsIcon } from '../../assets/images/settings_icon.svg'
import { ReactComponent as ReportingIcon } from '../../assets/images/reporting_icon.svg'
import CustomerSupportModal from '../../pages/dashboard/tables/modals/customer_support'
import { useAppDispatch, useAppSelector } from '../../redux/store'

export const AppHeader = React.memo(() => {
  const dispatch = useAppDispatch()
  const user: any = useAppSelector((state) => state.user).userInfo
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [showCustomerSupportModal, setShowCustomerSupportModal] = React.useState(false)

  const handleShowCustomerSupportModal = React.useCallback(() => {
    setShowCustomerSupportModal(true)
  }, [])

  const handleNavigateToSettings = React.useCallback(() => {
    navigate('/settings')
  }, [])

  const handleNavigateToReporting = React.useCallback(() => {
    navigate('/reporting')
  }, [])

  const handleNavigateToServices = React.useCallback(() => {
    navigate('/services')
  }, [])

  const handleNavigateToDashboard = React.useCallback(() => {
    navigate('/dashboard')
  }, [])

  const handleNavigateToAccount = React.useCallback(() => {
    navigate('/profile')
  }, [])

  useEffect(() => {
    if (!user) {
      dispatch(userIsAuth())
        .unwrap()
        .then(() => setLoading(false))
        .catch(() => setLoading(false))
    }

    if (user) {
      window.pylon = {
        chat_settings: {
          app_id: '0dba966f-34c6-4d4e-942c-f34bebf53027',
          email: user.email,
          name: user.branch.name,
          shop_name: user.branch.name,
          avatar_url: user.provider.logo_image,
        },
      }

      if (window.Pylon && typeof window.Pylon.initialize === 'function') {
        window.Pylon.initialize()
      }
    }
  }, [user, dispatch])

  const onLogout = () => {
    setLoading(true)
    dispatch<any>(logoutUser())
      .then(unwrapResult)
      .then((userInfo: any) => {
        navigate('/')
      })
      .catch((rejectedValueOrSerializedError: any) => {
        setLoading(false)
        console.log('error')
      })
  }

  return (
    <Box sx={{ display: 'flex', background: '#FCFCFC' }}>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          backgroundColor: '#FF7D26',
          height: '140px',
          justifyContent: 'center',
        }}
      >
        <Toolbar>
          <CarmaLogo onClick={handleNavigateToDashboard} cursor={'pointer'} />
          <Button
            onClick={handleNavigateToAccount}
            color="inherit"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '700',
              fontSize: '20px',
              color: 'whitesmoke',
              ml: 'auto',
              mr: 1,
            }}
          >
            <Avatar
              src={user?.provider?.logo_image ?? user?.logo_image}
              style={{ height: 50, width: 50, marginRight: 10 }}
            />
            {user && user.branch.name}
          </Button>
          <IconButton onClick={handleShowCustomerSupportModal}>
            <SupportIcon />
          </IconButton>
          <IconButton onClick={handleNavigateToReporting}>
            <ReportingIcon />
          </IconButton>
          <IconButton onClick={handleNavigateToSettings} sx={{ mr: 1 }}>
            <SettingsIcon />
          </IconButton>
          {/* <IconButton onClick={handleNavigateToServices} sx={{ marginRight: 2 }}>
            <ServicesIcon />
          </IconButton> */}
          <Button
            onClick={onLogout}
            sx={{ borderRadius: 24, backgroundColor: '#FF6600', paddingX: 2, paddingY: 1, mr: 2 }}
          >
            <Typography
              sx={{
                color: 'whitesmoke',
                fontSize: '14px',
                fontWeight: '700',
                fontFamily: APP_FONT,
              }}
            >
              LOG OUT
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      {showCustomerSupportModal && (
        <CustomerSupportModal
          isOpen={showCustomerSupportModal}
          setIsOpen={setShowCustomerSupportModal}
        />
      )}
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  )
})
