import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { ServiceRequest } from '../../../../../models/service_request'
import { CreateOffer } from '../../../../../models/offer'
import { useNavigate } from 'react-router-dom'
import { APP_FONT } from '../../../../../constants/app_font'
import 'react-datepicker/dist/react-datepicker.css'
import './../styles.css'
import { createOffer } from '../../../../../redux/actions/offer'
import ModalTableHead from '../components/table_head'
import FilePreview from '../components/file_preview'
import { ServicePrices } from './offer_modal'
import { OfferBlockService, OfferServiceBlock } from './OfferServiceBlock'
import { useAppSelector } from '../../../../../redux/store'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import { formatPrice } from '../../../../../components/helper/helper'
import { RecommendedServicePart } from '../service.model'
import { calculateSubTotalPrice } from '../calculation'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflowY: 'auto',
}

interface Props {
  setOpen: (arg: boolean) => void
  open: boolean
  onHide: () => void
  service_request: ServiceRequest
  selectedTimeblock: string | undefined
  setSelectedTimeblock: (arg: any) => void
  alert: boolean
  setAlert: (arg: boolean) => void
}

export interface ServicePrice {
  [serviceId: string]: string
}

export default function DiagnosticModal({
  setOpen,
  open,
  setAlert,
  alert,
  onHide,
  service_request,
}: Props) {
  const dispatch = useDispatch()
  const [alertText, setAlertText] = useState('')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('0.00')
  const [tax, setTax] = useState<string>('0.00')
  const [services, setServices] = useState<OfferBlockService[]>([])
  // @ts-ignore
  const laborRate = useAppSelector((state) => state.user)?.userInfo?.branch?.labor_rate as string

  const { getParentService, isSuccess } = useGetParentService()

  const handleAddServicePart = (serviceIndex: number) => {
    setServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              parts: [
                ...service.parts,
                {
                  name: '',
                  quantity: 1,
                  price_per_unit: 0,
                  number: '',
                },
              ],
            }
          : service
      )
    )
  }

  const handleServicePartChange = (
    index: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => {
    setServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          return {
            ...service,
            parts: service.parts.map((part, pIndex) => {
              if (pIndex === partIndex) {
                return { ...part, [field]: value }
              }
              return part
            }),
          }
        }
        return service
      })
    )
  }

  const handleServiceChange = (
    serviceIndex: number,
    field: keyof OfferBlockService | string,
    value: string | number | string[]
  ) => {
    setServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              [field]: value,
            }
          : service
      )
    )
  }

  const handleRemoveServicePart = (serviceIndex: number, partIndex: number) => {
    setServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              parts: service.parts.filter((_, pIndex) => pIndex !== partIndex),
            }
          : service
      )
    )
  }

  const handleAdditionalDataChange = useCallback(
    (index: number, field: string, value: string | string[] | number) => {
      //   changes values in service.additional_data
      //   @ts-ignore
      setServices((currentServices) =>
        currentServices.map((service, idx) => {
          if (idx === index) {
            return {
              ...service,
              additional_data: {
                ...service.additional_data,
                [field]: value,
              },
            }
          }
          return service
        })
      )
    },
    []
  )

  useEffect(() => {
    if (isSuccess) {
      const initialServices: OfferBlockService[] = []
      service_request.services.forEach((service) => {
        const serviceId = service.service_id
        const parentService = getParentService(serviceId)
        if (parentService) {
          initialServices.push({
            id: parentService.id,
            name: parentService.name,
            type: parentService.child?.id ?? null,
            types: parentService.children ?? null,
            serviceType: parentService.type,
            parts: [],
            price: 0,
            labor_hours: 0,
            labor_price: 0,
            labor_rate: Number(laborRate),
            additional_data: service.additional_data,
            vehicle: service_request.vehicle,
          })
        }
      })

      const fullDiagnosticService: OfferBlockService = {
        id: 'diagnostic_001',
        name: 'Full general diagnostic',
        type: '',
        types: [],
        parts: [],
        price: 0,
        labor_hours: 0,
        labor_price: 0,
        labor_rate: Number(laborRate),
        serviceType: 'DIAGNOSIS',
        additional_data: {} as any,
        vehicle: service_request.vehicle,
      }

      setServices([fullDiagnosticService, ...initialServices])
      setIsLoading(false)
    }
  }, [service_request?.services, isSuccess])

  useEffect(() => {
    const initialPrices: ServicePrices = {}
    service_request.services.forEach((service: any) => {
      const price = service.service.type === 'DIAGNOSIS' ? '0.00' : ''
      initialPrices[service.service_id] = price
    })

    setServicePrices(initialPrices)
  }, [service_request])

  const handleOfferSubmission = () => {
    if (totalFee === undefined || service_request.requestedDateTime === undefined) {
      setAlert(true)
    } else {
      const providerId = localStorage.getItem('branchId')
      if (providerId) {
        setIsLoading(true)
        const servicesArray = services
          .filter((service) => service.name !== 'Full general diagnostic')
          .map((service) => ({
            service_id: service.type || service.id,
            labor_hours: Number(service.labor_hours),
            parts: service.parts,
            additional_data: service.additional_data,
          }))

        const offer: CreateOffer = {
          ...(shopSupplyFees && { supply_fee: parseFloat(shopSupplyFees.replace(/,/g, '')) }),
          ...(tax && { tax: parseFloat(tax.replace(/,/g, '')) }),
          consumer_request_id: service_request.id,
          provider_branch_id: providerId,
          drop_off_by: new Date(service_request.requestedDateTime).toISOString(),
          services: servicesArray,
        }

        dispatch<any>(createOffer(offer))
          .then(unwrapResult)
          .then((offer: any) => {
            if (offer) {
              setOpen(false)
              navigate(0)
            }
          })
          .catch((error: any) => {
            setIsLoading(false)
            setAlert(true)
            setAlertText(error)
            console.log('error')
          })
      }
    }
  }

  useEffect(() => {
    const total = calculateSubTotalPrice(services)
    const formattedTotal = total.toFixed(2)
    setFee(formattedTotal)
  }, [services])

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice(services)

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [fee, tax, shopSupplyFees])

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  return (
    <Modal
      open={open}
      onClose={onHide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      sx={{ overflow: 'hidden' }}
    >
      <>
        <Box sx={style}>
          {alert && <Alert severity="error">{alertText}</Alert>}
          <ModalTableHead
            onHide={onHide}
            service_request={{
              slug: service_request.slug,
              vehicle: service_request.vehicle,
              services: service_request.services,
              drop_off_time: service_request.requestedDateTime,
              is_diagnostic: service_request.type === 'DIAGNOSTIC',
              offer_amount: undefined,
              pick_up_time: undefined,
              comment: service_request.additional_information ?? undefined,
              expires_at: service_request.expires_at,
            }}
          />
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                mb: 0,
                mt: 3,
              }}
            >
              {service_request.files.length > 0 ? (
                <Stack
                  spacing={1}
                  sx={{
                    width: '70%',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Concern media
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                    Photos and videos uploaded by customer to explain concern
                  </Typography>
                  <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
                    <FilePreview files={service_request.files || []} />
                  </Box>
                </Stack>
              ) : (
                ''
              )}
              <Stack
                spacing={1}
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '22px',
                    fontFamily: APP_FONT,
                    fontWeight: '700',
                    textAlign: 'left',
                    marginBottom: 1,
                  }}
                >
                  1. Diagnostic Requirements
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: APP_FONT,
                    fontWeight: '600',
                    marginBottom: 1,
                  }}
                >
                  Carma Partner Service Centers must complete diagnostics ASAP (maximum of 4 hours
                  after drop-off) to receive approval for recommended services from Fleet Customers.
                </Typography>
              </Stack>
              <Stack
                spacing={1}
                marginY={2}
                sx={{
                  width: '70%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '22px',
                    fontFamily: APP_FONT,
                    fontWeight: '700',
                    textAlign: 'left',
                    marginBottom: 1,
                  }}
                >
                  {'2. Your Offer'}
                </Typography>
                {services.map((item, index) => {
                  const isFullGeneralDiagnostic = item.name === 'Full general diagnostic'

                  return (
                    <OfferServiceBlock
                      service={item}
                      index={index}
                      handleServiceChange={handleServiceChange}
                      handleServicePartChange={handleServicePartChange}
                      handleAddServicePart={handleAddServicePart}
                      handleRemoveServicePart={handleRemoveServicePart}
                      handleAdditionalDataChange={handleAdditionalDataChange}
                      disableServiceChange={isFullGeneralDiagnostic}
                    />
                  )
                })}
                <Divider
                  sx={{
                    marginY: '4px !important',
                  }}
                />
                <Box display="flex" gap={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}
                  >
                    Subtotal:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {formatPrice(fee ?? '')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                      }}
                    >
                      Shop supply fees
                    </Typography>
                  </Stack>
                  <TextField
                    disabled={service_request.services.length === 0}
                    value={shopSupplyFees}
                    onChange={handleFeeChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      style: { background: '#fff' },
                    }}
                    inputProps={{
                      maxLength: 9,
                      style: { background: '#fff', height: '17px', textAlign: 'right' },
                    }}
                    style={{ width: '15%' }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                      }}
                    >
                      Tax
                    </Typography>
                  </Stack>
                  <TextField
                    disabled={service_request.services.length === 0}
                    value={tax}
                    onChange={handleTaxChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      style: { background: '#fff' },
                    }}
                    inputProps={{
                      maxLength: 9,
                      style: { background: '#fff', height: '17px', textAlign: 'right' },
                    }}
                    style={{ width: '15%' }}
                  />
                </Box>
                <Divider
                  sx={{
                    marginY: '8px !important',
                  }}
                />
                <Box display="flex">
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      width: '100%',
                    }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {formatPrice(totalFee ?? '')}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      marginTop: 3,
                    }}
                  >
                    <Button
                      disabled={isLoading}
                      onClick={handleOfferSubmission}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        borderRadius: 10,
                        marginTop: 3,
                        marginBottom: 3,
                        backgroundColor: '#FF6600',
                        height: 35,
                        width: 250,
                        ':hover': {
                          backgroundColor: '#DB5D08',
                        },
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontFamily: APP_FONT,
                          fontWeight: '700',
                        }}
                      >
                        SUBMIT OFFER
                      </div>
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
        {isLoading && (
          <Backdrop
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress
              size={60}
              sx={{
                color: '#FF6600',
              }}
            />
          </Backdrop>
        )}
      </>
    </Modal>
  )
}
