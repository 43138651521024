import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiCall } from '../../apis/api'
import { UpdateAccountSettings, DateRange } from '../../models/account_settings'
import { ActionTypes } from '../constants/action-types'
import { Profile } from '../../models/profile'
import { handleErrors } from '../../utils/helper'
import { WorkingHours } from '../../models/working_hours'

export const fetchAccount = createAsyncThunk(
  ActionTypes.FETCH_OFFERS,
  async (_, { rejectWithValue }) => {
    try {
      return await apiCall('/provider/account', 'get')
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const fetchVehicleClasses = createAsyncThunk(
  ActionTypes.FETCH_VEHICLE_CLASSES,
  async (_, { rejectWithValue }) => {
    try {
      return await apiCall('/vehicle-class', 'get')
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const fetchVehicleEngines = createAsyncThunk(
  ActionTypes.FETCH_VEHICLE_ENGINES,
  async (_, { rejectWithValue }) => {
    try {
      return await apiCall('/vehicle-engine', 'get')
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const generateActivitySummary = createAsyncThunk(
  ActionTypes.GENERATE_ACTIVITY_SUMMARY,
  async (body: DateRange, { rejectWithValue }) => {
    try {
      return await apiCall(`/provider/report/activity-summary?start_date=${body.start_date}&end_date=${body.end_date}`, 'get')
    } catch (error: any) {
      if (error.response && error.response.data.key) {
        return rejectWithValue(error.response.data.key)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const generateBalanceSummary = createAsyncThunk(
  ActionTypes.GENERATE_BALANCE_SUMMARY,
  async (_, { rejectWithValue }) => {
    try {
      return await apiCall('/provider/report/balance-summary', 'get')
    } catch (error: any) {
      if (error.response && error.response.data.key) {
        return rejectWithValue(error.response.data.key)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateAccountSettings = createAsyncThunk(
  ActionTypes.UPDATE_SETTINGS,
  async (body: UpdateAccountSettings, { rejectWithValue }) => {
    try {
      return await apiCall('/provider/account/settings', 'put', body)
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateProfile = createAsyncThunk(
  ActionTypes.UPDATE_PROFILE,
  async (body: Profile, { rejectWithValue }) => {
    try {
      return await apiCall('/provider/account', 'put', body)
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateLogo = createAsyncThunk(
  ActionTypes.UPDATE_LOGO,
  async (params: { file: File }, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('image', params.file)

      const data = await apiCall('provider/logo', 'put', formData)
      return data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const updateWorkingHours = createAsyncThunk(
  ActionTypes.UPDATE_WORKING_HOURS,
  async (body: WorkingHours, { rejectWithValue }) => {
    try {
      return await apiCall('/provider/account/working-hours', 'put', body)
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
