import React, { useEffect, useState } from 'react'

import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ReactComponent as CarmaLogo } from '../../../assets/images/carma_logo_white.svg'
import loginImage from '../../../assets/images/loginImage.png'
import { Account } from '../../../models/account'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../../redux/actions/user'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { unwrapResult } from '@reduxjs/toolkit'
import { APP_FONT } from '../../../constants/app_font'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

type RegisterBase = {
  email: string
}

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
})

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const submit: SubmitHandler<any> = async (register: RegisterBase) => {
    setLoading(true)
    const user: Account = {
      email: register.email,
    }
    dispatch<any>(forgotPassword(user))
      .then(unwrapResult)
      .then((userInfo: any) => {
        setLoading(false)
        navigate(`/reset-password?email=${register.email}`)
        window.alert(
          'Reset password email has been sent to you. You will receive a verification code'
        )
      })
      .catch((err: any) => {
        setLoading(false)
        setError(err)
      })
  }

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email format'),
  })

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const validateField = (fieldName: 'email') => {
    trigger(fieldName)
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: '#FF6600',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <CarmaLogo width={275} height={79} style={{ marginTop: 30 }} />
          <Box
            sx={{
              mx: 8,
              my: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '4%',
            }}
          >
            <Typography
              component="h6"
              variant="h6"
              fontWeight="bold"
              fontSize="14px"
              fontFamily="Figtree"
              textAlign={'center'}
              marginX={15}
            >
              To reset your password please enter your email and we will send you a reset link.
            </Typography>
            <Box component="form">
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                      validateField('email')
                      setError('')
                    }}
                    type="text"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email?.message?.toString()}
                    autoFocus
                  />
                )}
              />

              {error && (
                <Typography fontSize="14px" color="error">
                  {error}
                </Typography>
              )}

              <Button
                onClick={handleSubmit(submit)}
                type="submit"
                style={{ fontFamily: 'Figtree' }}
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 1,
                  borderRadius: 10,
                  backgroundColor: 'black',
                }}
              >
                Send email
              </Button>
            </Box>
          </Box>
          {isLoading && <CircularProgress color="inherit" />}
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default ForgotPassword
