import { Button, Modal, Typography, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

export default function ReportModal(props: any) {
  const { isReportGenerating, isReportGenerated, reportUrlPath, setIsOpen } = props

  if (isReportGenerating) {
    return (
        <Modal open={isReportGenerating} onClose={() => setIsOpen(false)}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '36%',
                    bgcolor: 'white',
                    boxShadow: 24,
                    borderRadius: 8,
                    p: 4,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: 'Figtree',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#000',
                    }}
                    >
                    Generating your report
                </Typography>
                <CircularProgress color="warning" size={60} />
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontFamily: 'Figtree',
                        fontWeight: '400',
                        textAlign: 'center',
                        color: '#000',
                        mt: 2,
                    }}
                >
                    Please do not refresh or exit the window
                </Typography>
            </Box>
        </Modal>
    )
  }

  if (isReportGenerated) {
    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const handleClick = () => {
        openInNewTab(`https://joincarma-images.s3.amazonaws.com/${reportUrlPath}`);
        setIsOpen(false)
    };
    return (
        <Modal open={isReportGenerated} onClose={() => setIsOpen(false)}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '36%',
                    bgcolor: 'white',
                    boxShadow: 24,
                    borderRadius: 8,
                    p: 4,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: 'Figtree',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#000',
                    }}
                    >
                    The requested report is ready
                </Typography>
                <Button
                    onClick={() => {
                        handleClick()
                    }}
                    sx={{ width: 150, height: 30, borderRadius: '20px' }}
                    variant="contained"
                    color="warning"
                    >
                    <Typography
                        sx={{
                        fontSize: '13px',
                        fontFamily: 'Figtree',
                        fontWeight: 'bold',
                        }}
                    >
                        Open Report
                    </Typography>
                </Button>
            </Box>
        </Modal>
    )
  }

  return (
    <Modal open={isReportGenerating} onClose={() => setIsOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '36%',
          bgcolor: 'white',
          boxShadow: 24,
          borderRadius: 8,
          p: 4,
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontFamily: 'Figtree',
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#000',
          }}
        >
          Could not generate the report
        </Typography>
        <Typography
            sx={{
                fontSize: '16px',
                fontFamily: 'Figtree',
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#000',
                marginTop: '5px'
            }}
            >
            Something went wrong. Please try again later.
        </Typography>
      </Box>
    </Modal>
  )
}
