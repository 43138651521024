import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './pages/authentication/login/login'
import Register from './pages/authentication/register/register'
import Dashboard from './pages/dashboard/dashboard'
import Settings from './pages/setting/settings'
import Reporting from './pages/reporting/reporting'
import '../src/pages/dashboard/tables/modals/styles.css'
import ResetPassword from './pages/authentication/reset_password/reset_password'
import ForgotPassword from './pages/authentication/forgot_password/forgot_password'
import Profile from './pages/profile'
import Perks from './pages/perks/perks'
import { createGlobalStyle } from 'styled-components'

import './index.css'
import { store } from './redux/store2'
import '@mantine/core/styles.css'
import { createTheme, MantineProvider } from '@mantine/core'

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Figtree', sans-serif;
    font-optical-sizing: auto;
  }
`

const theme = createTheme({
  /** Put your mantine theme override here */
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <MantineProvider theme={theme}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/reporting" element={<Reporting />} />
            {/* <Route path="/services" element={<Services />} /> */}
            <Route path="/perks" element={<Perks />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </BrowserRouter>
      </ReduxProvider>
    </MantineProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
