import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Backdrop,
  Autocomplete,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ReactComponent as CarmaLogo } from '../../../assets/images/carma_logo_white.svg'
import loginImage from '../../../assets/images/loginImage.png'
import { useDispatch } from 'react-redux'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { unwrapResult } from '@reduxjs/toolkit'
import { registerUser } from '../../../redux/actions/user'
import { APP_FONT } from '../../../constants/app_font'
import { PlacesAutocomplete } from '../../../components/PlacesAutoComplete/PlacesAutoComplete'
import { formatPhoneNumber } from '../../../utils/helper'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
})

type RegisterBase = {
  email: string
  first_name: string
  last_name: string
  password: string
  branch_name: string
  phone_number: string
  mobile_phone_number?: string
  address: string
  bio: string
  metro_area_id?: string
}

const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email format'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number'),
  branch_name: yup
    .string()
    .required('Business name is required')
    .max(80, 'Business name must be at most 80 characters'),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .length(14, 'Phone number must be 14 characters'),
  mobile_phone_number: yup
    .string()
    .nullable()
    .test(
      'is-length-14-or-empty',
      'Mobile phone number must be 14 characters',
      (value) => !value || value.length === 14
    ),
  bio: yup.string().required('Bio is required'),
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  address: yup.string().required('Address is required'),
  metro_area_id: yup.string().optional(),
})

const Register = () => {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState<any>()
  const [metroAreas, setMetroAreas] = useState<any[]>([])

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      branch_name: '',
      phone_number: '',
      mobile_phone_number: '',
      address: '',
      bio: '',
      metro_area_id: '',
    },
  })

  const onSubmit: SubmitHandler<any> = async (register: RegisterBase) => {
    setLoading(true)
    try {
      const resultAction = await dispatch<any>(registerUser(register))
      const userInfo = unwrapResult(resultAction)

      if (userInfo && userInfo.metroAreas) {
        setMetroAreas(userInfo.metroAreas)
      } else if (userInfo?.cognito_id) {
        window.location.href = userInfo.stripe_link
      } else {
        setError(userInfo)
      }
    } catch (err: any) {
      const errorMessages = err
        .map((errorObj: { field: string; error: string }) => {
          return `${errorObj.error}`
        })
        .join(', ')

      setError(errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const onAddressChange = (address: string) => {
    setValue('address', address)
    trigger('address')
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: '#FF6600',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <CarmaLogo width={275} height={79} style={{ marginTop: 30 }} />
          <Box
            sx={{
              mx: 8,
              my: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '4%',
            }}
          >
            <Typography component="h6" variant="h6" fontWeight="bold" fontSize="14px">
              Welcome, please sign up.
            </Typography>
            {error && (
              <Typography fontSize="14px" color="error">
                {error}
              </Typography>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    type="text"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoFocus
                    error={!!errors.email}
                    helperText={errors.email?.message?.toString()}
                  />
                )}
              />

              <Controller
                name="first_name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    type="text"
                    label="First name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoFocus
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message?.toString()}
                  />
                )}
              />

              <Controller
                name="last_name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    type="text"
                    label="Last name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    autoFocus
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message?.toString()}
                  />
                )}
              />

              <Controller
                name="branch_name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    type="text"
                    label="Business Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.branch_name}
                    helperText={errors.branch_name?.message?.toString()}
                  />
                )}
              />

              <Controller
                name="phone_number"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={formatPhoneNumber(value)}
                    onChange={(event) => {
                      onChange(formatPhoneNumber(event.target.value))
                    }}
                    type="text"
                    label="Shop Phone Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.phone_number}
                    helperText={errors.phone_number?.message?.toString()}
                  />
                )}
              />

              <Controller
                name="mobile_phone_number"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={formatPhoneNumber(value)}
                    onChange={(event) => {
                      onChange(formatPhoneNumber(event.target.value))
                    }}
                    type="text"
                    label="Mobile Number (to receive SMS)"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.mobile_phone_number}
                    helperText={errors.mobile_phone_number?.message?.toString()}
                  />
                )}
              />

              <Controller
                name="bio"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    type="text"
                    label="Bio"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.bio}
                    helperText={errors.bio?.message?.toString()}
                  />
                )}
              />
              {metroAreas.length > 0 && (
                <Controller
                  name="metro_area_id"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={metroAreas}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        onChange(newValue ? newValue.id : '')
                      }}
                      value={metroAreas.find((metro) => metro.id === value) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Metro area"
                          margin="normal"
                          variant="outlined"
                          error={!!errors.metro_area_id}
                          helperText={errors.metro_area_id?.message?.toString()}
                        />
                      )}
                      ListboxProps={{
                        style: {
                          maxHeight: 200,
                          overflow: 'auto',
                        },
                      }}
                    />
                  )}
                />
              )}
              <Controller
                name="address"
                control={control}
                render={() => (
                  <div style={{ margin: '14px 0 6px 0' }}>
                    <PlacesAutocomplete onChange={onAddressChange} />
                  </div>
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password?.message?.toString()}
                    InputLabelProps={{
                      style: { fontFamily: 'Figtree' },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} edge="end">
                            {showPassword ? (
                              <VisibilityOff color="action" />
                            ) : (
                              <Visibility color="action" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <p
                style={{
                  color: 'gray',
                  fontSize: 10,
                  marginTop: 0,
                  fontFamily: 'Figtree',
                }}
              >
                (Password must contain at least one upper case letter, one number, and one special
                character)
              </p>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 1,
                  borderRadius: 10,
                  backgroundColor: 'black',
                }}
              >
                Register
              </Button>
            </form>
          </Box>
          {isLoading && (
            <Backdrop
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              onClick={() => {}}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default Register
