import { Offer } from '../../../models/offer_new'

export const statusText = (row: Offer) => {
  const { status, files } = row
  const lowerCaseStatus = status.toLowerCase()

  if (lowerCaseStatus === 'accepted') {
    return 'Mark as arrived'
  } else if (lowerCaseStatus === 'in_progress') {
    if (files.length > 0) {
      const invoiceFile = files.find((el) => el.type === 'INVOICE')
      if (invoiceFile) {
        if (invoiceFile.is_approved === false) {
          return 'Invoice needs changes; see feedback and resubmit'
        }
        return 'Invoice review in progress'
      }
    }
    return 'Service in progress'
  } else if (lowerCaseStatus === 'diagnostic_in_progress') {
    if (files.length > 0) {
      const estimateFile = files.find((el) => el.type === 'ESTIMATE')
      if (estimateFile && estimateFile.is_approved === false) {
        return 'Diagnostic needs changes; see feedback and resubmit'
      }
      return 'Awaiting technician approval'
    }
    return 'Diagnostic in progress'
  } else if (lowerCaseStatus === 'pending_approval') {
    return 'Awaiting customer approval'
  } else if (lowerCaseStatus === 'inspection_in_progress') {
    if (files.length > 0) {
      const estimateFile = files.find((el) => el.type === 'WORK_ORDER')
      if (estimateFile && estimateFile.is_approved === false) {
        return 'Recommended services declined by technician'
      }
      return 'Recommended services awaiting technician approval'
    }
    return 'Inspection in progress'
  } else {
    return 'No Status'
  }
}
