import React, { useState, useEffect } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  styled,
  TableCell,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { ServiceRequest } from '../../../models/service_request'
import { IconButton, Typography } from '@mui/material'
import socket from '../../../sockets/socket'
import { APP_FONT } from '../../../constants/app_font'
import { ReactComponent as RequestIcon } from '../../../assets/images/missed_request_icon.svg'
import { fetchMissedServiceRequests } from '../../../redux/actions/service_request'
import Copy from '../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../assets/images/contentCopyFilled.svg'
import { formatDateTime } from '../../../utils/helper'
import { ArrowTooltip } from './modals/components/ui/arrow_tooltip'
import HoverPopper from '../../../components/hover/hover_popper'
import { formatPrice } from '../../../components/helper/helper'
import ServiceList from '../components/service_list'

type Props = {
  formatCurrency: any
}

const StickyHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  top: 0,
  zIndex: 10,
  position: 'sticky',
  boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)',
}))

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
    margin: 12px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
    margin: 12px;
  }
`

export default function EnhancedTable(props: Props) {
  const { formatCurrency } = props
  const [currentRequests, setCurrentRequests] = React.useState<ServiceRequest[]>([])
  const [alert, setAlert] = useState(false)
  const [selectedTimeblock, setSelectedTimeblock] = useState()
  const [copiedVin, setCopiedVin] = React.useState<string | null>(null)
  const [copiedComment, setCopiedComment] = React.useState<string | null>(null)
  const dispatch = useDispatch()

  const getNewSRs = () => {
    const providerId = localStorage.getItem('branchId')
    if (providerId)
      dispatch<any>(fetchMissedServiceRequests({ providerId }))
        .then(unwrapResult)
        .then((current_requests: any) => {
          setCurrentRequests(current_requests)
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('error')
        })
  }

  const getOfferStatus = (sr: ServiceRequest) => {
    if (sr.status?.status.toLowerCase() === 'cancelled') {
      return 'Canceled request'
    } else if (sr.status?.status.toLowerCase() === 'expired' && sr.offers && sr.offers.length > 0) {
      return 'Request expired: no offer was accepted'
    } else if (
      sr.status?.status.toLowerCase() === 'expired' &&
      (!sr.offers || sr.offers.length === 0)
    ) {
      return 'Request expired: no shops made offers'
    } else if (
      sr.offers.length > 0 &&
      sr.status?.status.toLowerCase() !== 'cancelled' &&
      sr.status?.status.toLowerCase() !== 'expired'
    ) {
      return 'Accepted another offer'
    }
  }

  const getBidAmount = (sr: ServiceRequest) => {
    const offer = sr.offers?.find((el) => el.is_accepted)
    if (!offer && sr.status?.status.toLowerCase() !== 'completed') {
      return '-'
    } else if (sr.type === 'DIAGNOSTIC' && offer) {
      return offer?.provider_branch?.labor_rate
        ? `Labor rate: ${formatPrice(offer?.provider_branch?.labor_rate)} per hour`
        : 'N/A'
    } else if (
      offer &&
      sr.status?.status.toLowerCase() !== 'cancelled' &&
      sr.status?.status.toLowerCase() !== 'expired'
    ) {
      return formatCurrency.format(offer?.total_fee || 0)
    }
  }

  useEffect(() => {
    getNewSRs()
    const providerId = localStorage.getItem('branchId')
    if (providerId) {
      socket.emit('subscribe', { branchId: providerId })
      socket.on('status-update', (payload) => {
        getNewSRs()
      })
    }

    return () => {
      socket.off('status-update')
    }
  }, [])

  const [open, setOpen] = React.useState(false)

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          marginTop: 5,
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      </Toolbar>
    )
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedTimeblock(undefined)
    setAlert(false)
  }

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => {
          setCopiedVin(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyComment = (event: React.MouseEvent, comment: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(comment)
      .then(() => {
        setCopiedComment(comment)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: -13,
        }}
      >
        <RequestIcon />
        <Typography
          sx={{
            marginTop: 4,
            fontFamily: APP_FONT,
            fontSize: 14,
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: '#AEAEAE',
          }}
          id="tableTitle"
          component="div"
        >
          Missed Requests
        </Typography>
      </Box>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer sx={{ px: 2, maxHeight: 800, overflowY: 'auto' }}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Request ID
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Created on
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle ID
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  VIN
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle class
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Engine type
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Engine size
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Services
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Reason
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Comments
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Winning offer
                </StickyHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRequests?.map((row: ServiceRequest) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {row.slug ?? row.id}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {formatDateTime(row.created_at)}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">{`${row.vehicle?.year} ${
                    row.vehicle?.make
                  } ${row.vehicle?.model} ${row.vehicle.trim ?? ''}`}</TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }}><div style={{ maxWidth: '125px' }}>{row.vehicle?.name}</div></TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                      {row.vehicle?.vin || 'VIN not provided'}
                      {row.vehicle?.vin && (
                        <ArrowTooltip style={{ margin: 0 }} title={copiedVin === row.vehicle?.vin ? 'Copied!' : ''}>
                          <StyledButton
                            onClick={(event) => handleCopyVin(event, row.vehicle?.vin ?? '')}
                          >
                            <img src={copiedVin === row.vehicle?.vin ? CopyFilled : Copy} alt="" />
                          </StyledButton>
                        </ArrowTooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="center">
                    {row.vehicle?.class?.name ? row.vehicle?.class?.name : ''}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {[row.vehicle?.engines].join(', ')}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {row.vehicle?.engine_size}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <Box>
                      <ServiceList row={row} />
                    </Box>
                    {(
                      row?.offers
                        ?.find((offer): any => offer.is_accepted === true)
                        ?.services.filter((service: any) => service.is_approved === true) ||
                      row?.services
                    )?.length -
                      3 >
                      0 && (
                      <HoverPopper
                        holder={
                          <Typography
                            sx={{
                              color: '#DB5D08',
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {`(${
                              (
                                row?.offers
                                  ?.find((offer): any => offer.is_accepted === true)
                                  ?.services.filter(
                                    (service: any) => service.is_approved === true
                                  ) || row?.services
                              )?.length || 1
                            }) View all`}
                          </Typography>
                        }
                      >
                        <Box
                          sx={{
                            backgroundColor: 'rgba(238, 238, 238, 0.95)',
                            pointerEvents: 'auto',
                            paddingTop: 3,
                            paddingLeft: 3,
                            paddingRight: 3,
                            paddingBottom: 1,
                          }}
                        >
                          {(
                            row?.offers
                              ?.find((offer): any => offer.is_accepted === true)
                              ?.services.filter((service: any) => service.is_approved === true) ||
                            row?.services
                          ).map((service: any, index: any) => {
                            let displayName = service?.service.name
                            if (
                              service?.service?.parent &&
                              service.service.parent.parent_id &&
                              service.service.parent.name
                            ) {
                              displayName = `${service.service.parent.name} (${displayName}${
                                service.service.type === 'TIRE' &&
                                row.vehicle.tires &&
                                row.vehicle.tires.length > 0
                                  ? `, ${row.vehicle.tires[0].width}/${row.vehicle.tires[0].ratio}${row.vehicle.tires[0].construction}${row.vehicle.tires[0].diameter}`
                                  : ''
                              })`
                            }

                            return (
                              <Typography
                                key={`a-${service.service.name}-${index}`}
                                mb={1.5}
                                fontFamily={APP_FONT}
                                fontSize={'14px'}
                              >
                                {displayName}
                              </Typography>
                            )
                          })}
                        </Box>
                      </HoverPopper>
                    )}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {getOfferStatus(row)}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center', maxWidth: '175px' }}>
                      {row.additional_information ? (
                        <>
                          {row.additional_information.length > 104 ? (
                            <HoverPopper
                              holder={
                                <span>
                                  {row.additional_information.substring(0, 20)}
                                  <span>...</span>
                                </span>
                              }
                            >
                              <Box
                                sx={{
                                  width: '500px',
                                  textAlign: 'center',
                                  backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                  pointerEvents: 'auto',
                                  p: 2,
                                }}
                              >
                                {row.additional_information}
                              </Box>
                            </HoverPopper>
                          ) : (
                            row.additional_information
                          )}
                          <ArrowTooltip
                            style={{ margin: 0 }}
                            title={copiedComment === row.additional_information ? 'Copied!' : ''}
                          >
                            <StyledButton
                              onClick={(e: any) =>
                                handleCopyComment(e, row.additional_information ?? '')
                              }
                            >
                              <img
                                src={
                                  copiedComment === row.additional_information ? CopyFilled : Copy
                                }
                                alt="Copy icon"
                              />
                            </StyledButton>
                          </ArrowTooltip>
                        </>
                      ) : (
                        'No additional information'
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {getBidAmount(row)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
