import { z } from 'zod'

export interface Offer {
  consumer: Consumer
  consumer_request: ConsumerRequest
  consumer_request_id: string
  created_at: string
  drop_off_by: string
  diagnostic_result?: string
  files: File[]
  id: string
  in_mileage: number
  instruction: string
  is_accepted: boolean
  is_automated: boolean
  labor_rate: number | null
  mileage?: any // does this actually exist on this object?
  new_supply_fee: string
  new_tax: string
  out_mileage: number
  pick_up_after: string
  pick_up_after_is_verified: boolean
  provider_branch_id: string
  provider_drop_off_by?: string
  requestedDateTime: string
  services: ProviderRequestServiceType[]
  services_fully_approved?: boolean
  slug: string
  status: string
  status_logs: StatusLog[]
  supply_fee: string
  tax: string
  total_fee: string
  vehicle: Vehicle
}

export const calculateOfferAmount = (offer: Offer) => {
  const value =
    Number(offer.new_supply_fee ? offer.new_supply_fee : offer.supply_fee) +
    Number(offer.new_tax ? offer.new_tax : offer.tax) +
    offer.services
      .filter((service) => service.is_approved)
      .reduce((total, service) => total + (service.price ? parseFloat(service.price) : 0), 0)
  if (!isNaN(value)) {
    return value
  }
  return Number(offer.total_fee)
}

// making a custom data type for this since we're doing a lot of building on the backend for this

export const ProviderRequestService = z.object({
  id: z.string(),
  service_id: z.string(),
  name: z.string(),
  is_approved: z.boolean(),
  price: z.string(), // ????
  parts: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      quantity: z.number().int(),
      price_per_unit: z.number(),
      number: z.string(),
    })
  ),
  labor_hours: z.number(),
  additional_data: z
    .object({
      TIRE_POSITIONS: z.array(z.string()).optional(),
      TIRE_BRAND: z.union([z.string(), z.object({ name: z.string() })]).optional(),
    })
    .nullable(),
  type: z.string(),
  service: z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    parent: z
      .object({
        id: z.string(),
        parent_id: z.string(),
        name: z.string(),
      })
      .optional(),
    price: z.unknown(),
    type: z.string(),
    required_data: z.array(z.string()),
  }),
})

export type ProviderRequestServiceType = z.infer<typeof ProviderRequestService>

export const fullGeneralDiagnosticService: ProviderRequestServiceType = {
  id: '',
  service_id: '',
  is_approved: true,
  parts: [],
  labor_hours: 0,
  additional_data: null,
  name: 'Full general diagnostic',
  price: '0',
  type: '',
  service: {
    id: '',
    name: 'Full general diagnostic',
    description:
      "A comprehensive examination designed to identify issues within a vehicle's core systems. This may include interfacing with the vehicle’s onboard computer system and/or a visual inspection to determine the source of the problem as indicated by the vehicle owner. Allows users to detect existing or potential problems early, ensuring timely repairs and optimal vehicle performance.",
    parent: undefined,
    type: 'DIAGNOSIS',
    required_data: [],
  },
}

interface Consumer {
  id: string
  phone_number: string | null
  stripe_customer_id: string
  is_phone_number_verified: boolean | null
  created_at: string
  updated_at: string
  is_deleted: boolean
  metro_area_id: string
  is_business: boolean
  email: string
  is_email_verified: boolean
  name: string
  auth2_provider: string | null
  is_blocked: boolean
  default_pay_method_id: string
  additional_phone_numbers: string[] | null
}

export interface ConsumerRequest {
  id: string
  consumer_id: string
  vehicle_id: string
  created_at: string
  rating: number | null
  review_comment: string | null
  reviewed_at: string | null
  expires_at: string
  is_finished: boolean
  drop_off_date: string
  drop_off_time: string[]
  included_provider_branch_ids: string[]
  slug: string
  provider_notification_sent: boolean
  is_payment_complete: boolean
  coords: Coords
  average_fee: number
  one_day_reminder: boolean
  one_hour_reminder: boolean
  metro_area_id: string
  additional_information: string
  location_id: string | null
  type: string
  issue: string | null
  files: File[]
}

interface Coords {
  latitude: number
  longitude: number
}

interface StatusLog {
  consumer_request_id: string
  status: string
  created_at: string
}

export interface Vehicle {
  id: string
  vin: string
  aces: string | null
  make: string
  name: string | null
  trim: string
  year: string
  class: VehicleClass
  color: string
  model: string
  tires: Tire[]
  engines: string[]
  mileage: number | null
  class_id: string
  created_at: string
  is_default: boolean
  is_deleted: boolean
  updated_at: string
  warranties: any[]
  consumer_id: string
  engine_size: number | null
  engine_hours: number | null
  license_plate: string | null
  date_of_purchase: string | null
  is_under_warranty: boolean
  has_different_tires: boolean
  tracker_serial_number: string | null
  special_handlings: any[]
}

interface VehicleClass {
  id: string
  name: string
  order: number
  weight: WeightRange
}

interface WeightRange {
  to: number
  from: number
}

interface Tire {
  id: string
  ratio: number
  width: number
  diameter: number
  position: string
  load_index: number | null
  vehicle_id: string
  construction: string
  speed_rating: string | null
}

interface File {
  id: string
  key: string
  url: string
  name: string
  size: number
  created_at: string
  consumer_request_id: string
  type?: string
  is_approved?: boolean
  instruction_admin_provider?: string
}
