import { useState } from 'react'
import HoverPopper from '../../../../../components/hover/hover_popper'
import { Box, IconButton, styled } from '@mui/material'
import { ArrowTooltip } from './ui/arrow_tooltip'
import CopyFilled from '../../../../../assets/images/contentCopyFilled.svg'
import Copy from '../../../../../assets/images/contentCopy.svg'

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
  }
`

export function CommentCell({
  comment,
  maxLength = 104,
}: {
  comment?: string
  maxLength?: number
}) {
  const [copiedComment, setCopiedComment] = useState<string | null>(null)

  const handleCopyComment = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedComment(vin)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
      {comment ? (
        <>
          {comment.length > maxLength ? (
            <HoverPopper
              holder={
                <span style={{ wordBreak: 'break-all' }}>
                  {comment.substring(0, maxLength)}
                  <span style={{ color: '#FF7D26' }}>...</span>
                </span>
              }
            >
              <Box
                sx={{
                  width: '500px',
                  backgroundColor: 'rgba(238, 238, 238, 0.95)',
                  pointerEvents: 'auto',
                  p: 2,
                  whiteSpace: 'normal',
                }}
              >
                {comment}
              </Box>
            </HoverPopper>
          ) : (
            comment
          )}
          <ArrowTooltip title={copiedComment === comment ? 'Copied!' : ''}>
            <StyledButton onClick={(e: any) => handleCopyComment(e, comment ?? '')}>
              <img src={copiedComment === comment ? CopyFilled : Copy} alt="Copy icon" />
            </StyledButton>
          </ArrowTooltip>
        </>
      ) : (
        'N/A'
      )}
    </div>
  )
}
