import React, { useEffect, useState } from 'react'
import { Box, Modal, Typography, TextField, Button, Grid } from '@mui/material'
import { Metadata, ShopPerk } from '../models/shop-perk'

interface DynamicEditModalProps {
  open: boolean
  handleClose: () => void
  perk: ShopPerk | null
  onSave: (perk: ShopPerk, meta_data: Metadata) => void
}

interface ValidationState {
  [field: string]: {
    isValid: boolean
    message: string
  }
}

const currentYear = new Date().getFullYear()

const DynamicEditModal: React.FC<DynamicEditModalProps> = ({ open, handleClose, perk, onSave }) => {
  const [editFields, setEditFields] = useState<Metadata>({})
  const [validation, setValidation] = useState<ValidationState>({})
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  useEffect(() => {
    if (perk?.meta_data) {
      setEditFields(perk.meta_data)
    }
  }, [perk])

  const validateField = (field: string, value: number): boolean => {
    switch (field) {
      case 'day':
        return value >= 1 && value <= 365
      case 'year':
        return value >= 1 && value <= 50
      case 'mile':
        return value >= 1 && value <= 100000
      default:
        return true
    }
  }

  const getValidationMessage = (field: string): string => {
    switch (field) {
      case 'day':
        return 'Days must be between 1 and 365'
      case 'year':
        return 'Years must be between 1 and 50'
      case 'mile':
        return 'Miles must be between 1 and 100,000'
      default:
        return ''
    }
  }

  const handleChange = (field: string, value: string) => {
    const numericValue = parseInt(value)
    const isValid = validateField(field, numericValue)

    if (numericValue < 0) {
      return 0
    }

    setEditFields((prev) => ({ ...prev, [field]: value }))
    setValidation((prev) => ({
      ...prev,
      [field]: { isValid, message: isValid ? '' : getValidationMessage(field) },
    }))

    const allValid = Object.entries({ ...editFields, [field]: value }).every(([key, val]) =>
      validateField(key, Number(val))
    )
    setIsFormValid(allValid)
  }

  const handleSave = (): void => {
    if (perk && isFormValid) {
      const metaDataToSave = perk.provider_branch?.meta_data
        ? { ...perk.provider_branch.meta_data, ...editFields }
        : editFields

      if (Object.values(metaDataToSave).every((value) => value !== null && value !== '')) {
        onSave(perk, metaDataToSave)
      } else {
        console.error('All metadata fields must be filled out.')
      }
    }
    handleClose()
  }

  const pluralizeLabel = (label: string) => {
    if (label === 'day') return 'Days'
    if (label === 'year') return 'Years'
    if (label === 'mile') return 'Miles'
    return label.charAt(0).toUpperCase() + label.slice(1)
  }

  type FieldPairs = [string, string?][]

  const createFieldPairs = (editFields: Metadata): FieldPairs => {
    const orderedKeys = Object.keys(editFields)
      .filter((key) => key !== 'header')
      .sort()
      .reverse()

    return orderedKeys.reduce<FieldPairs>((result, field, index, array) => {
      if (index % 2 === 0) {
        result.push([field, array[index + 1]])
      }
      return result
    }, [])
  }

  const fieldPairs: FieldPairs = createFieldPairs(editFields)
  const singleField = fieldPairs.length === 1 && !fieldPairs[0][1]

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-perk-modal"
      aria-describedby="edit-perk-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 540,
          bgcolor: 'white',
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
        }}
      >
        <Typography
          id="edit-perk-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 2 }}
        >
          Perk details
        </Typography>
        <Box sx={{ border: 2, borderColor: '#FF6600', p: 1, marginBottom: 2 }}>
          <Typography
            id="edit-perk-modal-description"
            variant="h6"
            component="h2"
            sx={{ color: '#FF6600', textAlign: 'center', fontWeight: 'bold' }}
          >
            {perk?.description}
          </Typography>
        </Box>
        {perk?.meta_data?.header && (
          <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', marginBottom: 2 }}>
            {perk.meta_data.header}
          </Typography>
        )}
        <Grid container spacing={2}>
          {fieldPairs.map(([field1, field2], index) => (
            <React.Fragment key={index}>
              <Grid item xs={singleField ? 12 : 6}>
                <TextField
                  margin="dense"
                  id={field1}
                  label={pluralizeLabel(field1)}
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={editFields[field1] || ''}
                  InputLabelProps={{
                    shrink: !!editFields[field1],
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => handleChange(field1, e.target.value)}
                  error={!validation[field1]?.isValid}
                  helperText={validation[field1]?.message}
                />
              </Grid>
              {field2 && (
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id={field2}
                    label={pluralizeLabel(field2)}
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editFields[field2] || ''}
                    InputLabelProps={{
                      shrink: !!editFields[field2],
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    onChange={(e) => handleChange(field2, e.target.value)}
                    error={!validation[field2]?.isValid}
                    helperText={validation[field2]?.message}
                  />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleClose}
            type="button"
            sx={{
              mt: 2,
              mb: 1,
              borderRadius: 10,
              marginRight: '10px',
              fontFamily: 'Figtree',
              color: '#FF6600',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={!isFormValid}
            type="button"
            variant="contained"
            sx={{
              mt: 2,
              mb: 1,
              borderRadius: 10,
              backgroundColor: '#FF6600',

              fontFamily: 'Figtree',
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DynamicEditModal
