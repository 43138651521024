import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  styled,
  IconButton,
  Typography,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import OfferModal from './modals/offer_create/offer_modal'
import {
  fetchMissedServiceRequests,
  fetchServiceRequests,
} from '../../../redux/actions/service_request'
import { unwrapResult } from '@reduxjs/toolkit'
import { ServiceRequest } from '../../../models/service_request'
import socket from '../../../sockets/socket'
import { APP_FONT } from '../../../constants/app_font'
import { ReactComponent as RequestIcon } from '../../../assets/images/requests_icon.svg'
import { CountdownTimer } from './modals/countdown_timer'
import { calculateTimeLeft, formatDateTime } from '../../../utils/helper'
import Copy from '../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../assets/images/contentCopyFilled.svg'
import HoverPopper from '../../../components/hover/hover_popper'
import DiagnosticModal from './modals/offer_create/diagnostic_modal'
import { InfoChip } from './modals/components/ui/info_chip'
import { ArrowTooltip } from './modals/components/ui/arrow_tooltip'
import ServiceList from '../components/service_list'

export default function EnhancedTable() {
  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>([])
  const [selectedSR, setSelectedSR] = useState<ServiceRequest | null>()
  const [alert, setAlert] = useState(false)
  const [selectedTimeblock, setSelectedTimeblock] = useState()
  const [copiedVin, setCopiedVin] = useState<string | null>(null)
  const [copiedComment, setCopiedComment] = useState<string | null>(null)
  const [hasNewRequests, setHasNewRequests] = useState(false)
  const [newRequestsCount, setNewRequestsCount] = useState(0)
  const [isTabActive, setIsTabActive] = useState(true)
  const dispatch = useDispatch()

  const StickyHeaderTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    top: 0,
    zIndex: 10,
    position: 'sticky',
    boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)',
  }))

  const StyledButton = styled(IconButton)`
    & {
      width: 35px;
      height: 35px;
      margin: 12px;
    }
    ,
    &&:hover {
      width: 35px;
      height: 35px;
      background-color: #fff0eb;
      margin: 12px;
    }
  `
  const getDropOffDateTime = (service_request: any) => {
    if (
      !service_request.drop_off_date ||
      !service_request.drop_off_time ||
      service_request.drop_off_time.length === 0
    ) {
      console.error('Invalid drop-off date or time')
      return null
    }
    const [datePart] = service_request.drop_off_date.split('T')
    const timeStr = service_request.drop_off_time[0].split(' ')[0]

    const [year, month, day] = datePart.split('-').map((num: any) => parseInt(num, 10))
    const period = service_request.drop_off_time[0].split(' ')[1]
    let [hours, minutes] = timeStr.split(':').map((str: string) => parseInt(str, 10))

    if (period === 'PM' && hours < 12) hours += 12
    if (period === 'AM' && hours === 12) hours = 0

    const dropOffDate = new Date(year, month - 1, day, hours, minutes, 0, 0)

    return dropOffDate.toISOString()
  }

  const getNewSRs = () => {
    const providerId = localStorage.getItem('branchId')
    if (providerId)
      dispatch<any>(
        fetchServiceRequests({
          providerId,
        })
      )
        .then(unwrapResult)
        .then(async (service_requests: any) => {
          setServiceRequests((prevServiceRequests) => {
            const newRequests = service_requests.filter(
              (fetchedSR: any) =>
                !prevServiceRequests.some((existingSR) => existingSR.id === fetchedSR.id)
            )
            if (newRequests.length > 0) {
              setNewRequestsCount((prevCount) => prevCount + newRequests.length)
              setHasNewRequests(true)
            }
            return service_requests
          })
          const mappedServiceRequests = service_requests.map((sr: ServiceRequest) => {
            return {
              ...sr,
              requestedDateTime: getDropOffDateTime(sr),
            }
          })
          setServiceRequests(mappedServiceRequests)
        })
        .catch((error: any) => {
          console.log(error)
        })
  }

  const handleTimerExpire = (expiredRequestId: string) => {
    const providerId = localStorage.getItem('branchId')
    const updatedRequests = serviceRequests.filter((req) => req.id !== expiredRequestId)
    setServiceRequests(updatedRequests)

    dispatch<any>(fetchMissedServiceRequests({ providerId }))
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsTabActive(true)
      } else {
        setIsTabActive(false)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (!isTabActive && hasNewRequests && newRequestsCount > 0) {
      document.title = `(${newRequestsCount}) You have new request(s)!`
    } else {
      setNewRequestsCount(0)
      document.title = 'Carma'
    }
  }, [hasNewRequests, newRequestsCount, isTabActive])

  useEffect(() => {
    if (isTabActive) {
      setNewRequestsCount(0)
      setHasNewRequests(false)
    }
  }, [isTabActive])

  useEffect(() => {
    getNewSRs()

    const providerId = localStorage.getItem('branchId')
    if (providerId) {
      socket.emit('subscribe', { branchId: providerId })
      socket.on('new-request', (payload) => {
        getNewSRs()
      })
      socket.on('status-update', (payload) => {
        getNewSRs()
      })
    }

    return () => {
      socket.off('new-request')
    }
  }, [])

  const [open, setOpen] = React.useState(false)

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          marginTop: 5,
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      </Toolbar>
    )
  }

  const handleOpen = useCallback((service_request: ServiceRequest) => {
    setOpen(true)
    setSelectedSR(service_request)
  }, [])

  const handleClose = () => {
    setOpen(false)
    setSelectedTimeblock(undefined)
    setAlert(false)
  }

  const serviceRequest = {
    cursor: 'pointer',
    tableRow: {
      '&:hover': {
        backgroundColor: '#7EA5FF',
      },
    },
  }

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => {
          setCopiedVin(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyComment = (event: React.MouseEvent, comment: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(comment)
      .then(() => {
        setCopiedComment(comment)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: -13,
        }}
      >
        <RequestIcon />
        <Typography
          sx={{
            marginTop: 4,
            fontFamily: APP_FONT,
            fontSize: 14,
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: '#AEAEAE',
          }}
          id="tableTitle"
        >
          Service Requests
        </Typography>
      </Box>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer sx={{ px: 2 }}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Request ID
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle ID
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  VIN
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Vehicle class
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Engine type
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Engine size
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Services
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Drop-off time
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Comments
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Special handling requirements
                </StickyHeaderTableCell>
                <StickyHeaderTableCell
                  sx={{ fontWeight: '600', fontFamily: APP_FONT, whiteSpace: 'nowrap' }}
                  align="left"
                >
                  Offer period
                </StickyHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceRequests
                ? serviceRequests.map((row: ServiceRequest) => (
                    <TableRow
                      key={row.id}
                      hover
                      sx={serviceRequest}
                      onClick={() => handleOpen(row)}
                    >
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.slug ?? row.id}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">{`${row.vehicle.year} ${
                        row.vehicle.make
                      } ${row.vehicle.model} ${row.vehicle.trim ?? ''}`}</TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }}>{row.vehicle?.name}</TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap'}}>
                          {row.vehicle?.vin || 'VIN not provided'}
                          {row.vehicle?.vin && (
                            <ArrowTooltip style={{ margin: 0 }} title={copiedVin === row.vehicle?.vin ? 'Copied!' : ''}>
                              <StyledButton
                                onClick={(event) => handleCopyVin(event, row.vehicle?.vin ?? '')}
                              >
                                <img
                                  src={copiedVin === row.vehicle?.vin ? CopyFilled : Copy}
                                  alt=""
                                />
                              </StyledButton>
                            </ArrowTooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="center">
                        {row.vehicle?.class?.name ? row.vehicle?.class?.name : ''}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {[row.vehicle?.engines].join(', ')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.vehicle?.engine_size}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <Box>
                          <ServiceList row={row} />
                        </Box>
                        {row?.services?.length - 3 > 0 && (
                          <HoverPopper
                            holder={
                              <Typography
                                sx={{
                                  color: '#DB5D08',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                {`(${row?.services?.length || 1}) View all`}
                              </Typography>
                            }
                          >
                            <Box
                              sx={{
                                backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                pointerEvents: 'auto',
                                paddingTop: 3,
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 1,
                              }}
                            >
                              {row.services.map((service: any, index) => {
                                let displayName = service?.service.name
                                if (
                                  service?.service?.parent &&
                                  service.service.parent.parent_id &&
                                  service.service.parent.name
                                ) {
                                  displayName = `${service.service.parent.name} (${displayName}${
                                    service.service.type === 'TIRE' &&
                                    row.vehicle.tires &&
                                    row.vehicle.tires.length > 0
                                      ? `, ${row.vehicle.tires[0].width}/${row.vehicle.tires[0].ratio}${row.vehicle.tires[0].construction}${row.vehicle.tires[0].diameter}`
                                      : ''
                                  })(${displayName}${
                                    service.additional_data?.TIRE_POSITIONS.length > 0
                                      ? ` (${service.additional_data.TIRE_POSITIONS?.join(',')})`
                                      : ''
                                  })`
                                }

                                return (
                                  <Typography
                                    key={`a-${service.service.name}-${index}`}
                                    mb={1.5}
                                    fontFamily={APP_FONT}
                                    fontSize={'14px'}
                                  >
                                    {displayName}
                                  </Typography>
                                )
                              })}
                            </Box>
                          </HoverPopper>
                        )}
                      </TableCell>

                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <Box>{row.requestedDateTime && formatDateTime(row.requestedDateTime)} </Box>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                          {row.additional_information ? (
                            <>
                              {row.additional_information.length > 104 ? (
                                <HoverPopper
                                  holder={
                                    <span>
                                      {row.additional_information.substring(0, 104)}
                                      <span style={{ color: '#FF7D26' }}>...</span>
                                    </span>
                                  }
                                >
                                  <Box
                                    sx={{
                                      width: '500px',
                                      textAlign: 'center',
                                      backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                      pointerEvents: 'auto',
                                      p: 2,
                                    }}
                                  >
                                    {row.additional_information}
                                  </Box>
                                </HoverPopper>
                              ) : (
                                row.additional_information
                              )}
                              <ArrowTooltip
                                style={{ margin: 0 }}
                                title={
                                  copiedComment === row.additional_information ? 'Copied!' : ''
                                }
                              >
                                <StyledButton
                                  onClick={(e: any) =>
                                    handleCopyComment(e, row.additional_information ?? '')
                                  }
                                >
                                  <img
                                    src={
                                      copiedComment === row.additional_information
                                        ? CopyFilled
                                        : Copy
                                    }
                                    alt="Copy icon"
                                  />
                                </StyledButton>
                              </ArrowTooltip>
                            </>
                          ) : (
                            'No additional information'
                          )}
                        </div>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                          {row.vehicle.special_handlings &&
                          row.vehicle.special_handlings.length > 0 ? (
                            <HoverPopper
                              holder={
                                <InfoChip
                                  label={row.vehicle.special_handlings[0].special_handling.title}
                                />
                              }
                            >
                              <Box
                                sx={{
                                  width: '500px',
                                  textAlign: 'center',
                                  backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                  pointerEvents: 'auto',
                                  paddingTop: 1,
                                  paddingLeft: 3,
                                  paddingRight: 3,
                                  paddingBottom: 1,
                                }}
                              >
                                {' '}
                                {row.vehicle.special_handlings[0].special_handling.description}
                              </Box>
                            </HoverPopper>
                          ) : (
                            'N/A'
                          )}
                        </div>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.expires_at && (
                          <CountdownTimer
                            initialTime={calculateTimeLeft(row.expires_at)}
                            onTimerExpire={() => {
                              handleTimerExpire(row.id)
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                : ''}
            </TableBody>
          </Table>

          {selectedSR &&
            (selectedSR.type === 'DIAGNOSTIC' ? (
              <DiagnosticModal
                setOpen={setOpen}
                open={open}
                onHide={handleClose}
                service_request={selectedSR}
                alert={alert}
                setAlert={setAlert}
                selectedTimeblock={selectedTimeblock}
                setSelectedTimeblock={setSelectedTimeblock}
              />
            ) : (
              <OfferModal
                setOpen={setOpen}
                open={open}
                onHide={handleClose}
                service_request={selectedSR}
                alert={alert}
                setAlert={setAlert}
                selectedTimeblock={selectedTimeblock}
                setSelectedTimeblock={setSelectedTimeblock}
              />
            ))}
        </TableContainer>
      </Paper>
    </Box>
  )
}
