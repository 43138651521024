import React, { useCallback, useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { useLazyListVehicleBrandsQuery } from '../../../../../../redux/api/vehicleApi/vehicleApi'
import { VehicleBrand } from '../../../../../../redux/api/vehicleApi/vehicleApi.types'

type TireBrandSelectProps = {
  value: string
  onChange: (value: string) => void
}

export const TireBrandSelect = ({ value, onChange }: TireBrandSelectProps) => {
  const [items, setItems] = useState<VehicleBrand[]>([])

  const [listVehicleBrands] = useLazyListVehicleBrandsQuery()

  const fetchAllPages = useCallback(async () => {
    //   page parameter is passed to listVehicleBrands function. The response includes count parameter which is the total number of items in the list. The limit is also passed as 100.
    //   The loop will fetch all pages of the list and add the items to the items array.
    let page = 1
    let count = 0
    let limit = 100
    let items: VehicleBrand[] = []
    do {
      try {
        const response = await listVehicleBrands({ page, limit }).unwrap()
        items = items.concat(response.items)
        count = response.count
        page++
      } catch (error) {
        // TODO: if response is an error, we should handle it
        console.error(error)
        break
      }
    } while (items.length < count)
    setItems(items)
  }, [listVehicleBrands])

  useEffect(() => {
    fetchAllPages()
  }, [fetchAllPages])

  return (
    <Autocomplete
      value={items.find((item) => item.name === value) || ''}
      onChange={(event, newValue: any) => onChange(newValue)}
      options={items}
      getOptionLabel={(option) => option?.name ?? ''}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Tire brand"
          placeholder="Select tire brand"
          sx={{
            backgroundColor: '#ffffff',
          }}
        />
      )}
      disableClearable
      freeSolo
    />
  )
}
