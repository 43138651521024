import { List, ListItem, Typography } from '@mui/material'
import { APP_FONT } from '../../../constants/app_font'

const generateDisplayName = (item, row) => {
  let displayName = `${item?.service.name}${
    item.additional_data?.TIRE_POSITIONS?.length > 0
      ? ` (${item.additional_data.TIRE_POSITIONS?.join(',')})`
      : ''
  }`

  if (item?.service?.parent && item.service.parent.parent_id && item.service.parent.name) {
    displayName = `${item.service.parent.name} (${displayName}${
      item.service.type === 'TIRE' && row.vehicle?.tires && row.vehicle.tires.length > 0
        ? `, ${row.vehicle.tires[0].width}/${row.vehicle.tires[0].ratio}${row.vehicle.tires[0].construction}${row.vehicle.tires[0].diameter}`
        : ''
    })`
  }

  return displayName
}

const ServiceList = ({ row, filter = false }) => {
  let filteredServices = row?.services
  if (filter) {
    filteredServices = row?.services.filter((item) => item?.is_approved)
  }

  const services =
    row?.consumer_request?.type === 'DIAGNOSTIC' || row?.type === 'DIAGNOSTIC'
      ? [...filteredServices, { service: { name: 'Full general diagnostic' } }]
      : row?.services?.slice(0, 3)

  return (
    <List sx={{ listStyleType: 'disc', paddingLeft: '20px' }}>
      {services.map((item, index) => {
        const displayName = generateDisplayName(item, row)
        return (
          <ListItem
            key={`a-${item.service.name}-${index}`}
            sx={{ display: 'list-item', padding: 0 }}
          >
            <Typography mb={1.5} fontFamily={APP_FONT} fontSize={'14px'}>
              {displayName}
            </Typography>
          </ListItem>
        )
      })}
    </List>
  )
}

export default ServiceList
